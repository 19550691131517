<script setup lang="ts">
import type { Organization } from "@/model/app/organization"
import type { Championship } from "@/model/championship/championship"
import type { ChampionshipEventType } from "@/model/championship/championship-event-type"
import type { Issue } from "@/model/tasks/issue"
import {
    useOrganizationSelection,
    type SelectionTableOrganization,
} from "@/pages/App/Organization/useOrganizationSelection"
import { $currency, $date, useApp, useAppConfig, useHttpClient } from "@/vf"
import { ref, toRefs } from "vue"
import { useRouter } from "vue-router"

const props = defineProps<{
    event: Championship
}>()

async function select(org: SelectionTableOrganization | Organization) {
    const issue = (await http.post<Issue>(`/championship/registration/${props.event.id}/create-issue/${org.id}`)).data
    await router.push({ name: "@Tasks.Customer.index", query: { issue: issue.id } })
}

const { event } = toRefs(props)
const { apiLink } = useApp()
const { appConfig } = useAppConfig()
const http = useHttpClient()
const router = useRouter()
const expanded = ref(false)
const organizationService = useOrganizationSelection()
if (appConfig.value.account) {
    await organizationService.fetchOrganizations()
}
const selection = ref(organizationService.tree)
</script>

<template>
    <div class="event-card">
        <div class="row event-card__row">
            <div class="col-lg-2 col-4 event-card__col">
                <div class="event-card__logo" v-if="event.logoName">
                    <img class="img-fluid" :alt="event.brandName" :src="apiLink(`/media/${event.logoName}`)" />
                </div>
            </div>
            <div class="col-lg-2 col-8 event-card__col event-card__col--name">
                <b>{{ $t("@championship:registration.event.name") }}</b>
                <div>{{ event.name }}</div>
            </div>
            <div class="col-lg-2 col-md-4 col-12 event-card__col">
                <b class="d-none d-md-block">{{ $t("@championship:registration.event.location") }}</b>
                <div>
                    {{ event.location }}
                    <span class="d-lg-none d-xl-none">({{ event.city }})</span>
                </div>
            </div>
            <div class="col-lg-2 col-12 event-card__col d-none d-lg-block">
                <b>{{ $t("@championship:registration.event.city") }}</b>
                <div>{{ event.city }}</div>
            </div>
            <div class="col-lg-4 col-md-8 col-12 event-card__col event-card__col--date">
                <b class="d-none d-md-block">{{ $t("@championship:registration.date") }}</b>
                <div>{{ $date(event.dateFrom) }} - {{ $date(event.dateUntil) }}</div>
            </div>
            <div class="event-card__toggle" :class="{ active: expanded }" @click="expanded = !expanded">
                <i class="fa fa-fw fa-plus"></i>
            </div>
        </div>
        <div v-animate-show="expanded" class="event-card-show overflow-hidden mb-4">
            <div class="event-card-show__inner">
                <div class="row">
                    <div class="col-xl-3 col-lg-6 col-12 order-1">
                        <h3 class="event-card-show__title">{{ $t("@championship:championship.eventInformation") }}</h3>
                        <p class="mb-0">
                            <b>{{ $t("@championship:championship.eventType") }}:</b>
                            {{ (event.eventTypes as ChampionshipEventType[] | undefined)?.map(c => c.name).join(", ") }}
                        </p>
                        <p>
                            <b>{{ $t("@championship:championship.bids") }}:</b>
                            {{ event.bids?.map(c => c.name).join(", ") }}
                        </p>
                        <address>
                            {{ event.brandName }}
                            <br />
                            {{ event.brandStreet }} {{ event.brandStreetNumber }}
                            <br />
                            {{ event.brandZip }} {{ event.brandCity }}
                        </address>
                        <b>{{ $t("@championship:championship.location") }}:</b>
                        <address>
                            {{ event.location }}
                            <br />
                            {{ event.locationStreet }} {{ event.locationStreetNumber }}
                            <br />
                            {{ event.locationZip }} {{ event.locationCity }}
                        </address>
                    </div>
                    <div
                        class="col-xl-5 col-12 order-3 order-xl-2 col-12 d-none d-lg-block"
                        v-for="price of event.prices"
                    >
                        <h3 class="event-card-show__title">{{ $t("@championship:championship.priceInformation") }}</h3>
                        <table class="table event-card-show__table">
                            <tbody>
                                <tr>
                                    <th>{{ price.name }}</th>
                                    <th class="text-right">{{ $t("@championship:championship.prices.early_rate") }}</th>
                                    <th class="text-right">
                                        {{ $t("@championship:championship.prices.normal_rate") }}
                                    </th>
                                </tr>
                                <tr>
                                    <td>{{ $t("@championship:championship.prices.active_participant") }}</td>
                                    <td class="text-right">{{ $currency(price.priceEarlyActiveCents) }}</td>
                                    <td class="text-right">{{ $currency(price.priceNormalActiveCents) }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t("@championship:championship.prices.plus_category") }}</td>
                                    <td class="text-right">{{ $currency(price.priceEarlyAdditionalCategoryCents) }}</td>
                                    <td class="text-right">
                                        {{ $currency(price.priceNormalAdditionalCategoryCents) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $t("@championship:championship.prices.coach") }}</td>
                                    <td class="text-right">{{ $currency(price.priceEarlyCoachCents) }}</td>
                                    <td class="text-right">{{ $currency(price.priceNormalCoachCents) }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t("@championship:championship.prices.escort") }}</td>
                                    <td class="text-right">{{ $currency(price.priceEarlyEscortCents) }}</td>
                                    <td class="text-right">{{ $currency(price.priceNormalEscortCents) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-12 order-2 order-xl-2">
                        <div class="event-card-show__registration d-none d-lg-block">
                            <h3 class="event-card-show__title">{{ $t("@championship:championship.registration") }}</h3>
                            <p>
                                {{ $t("@championship:championship.registration." + event.registrationStatus) }}
                                <time :datetime="event.registrationFrom" v-if="event.registrationStatus == 'willOpen'">
                                    {{ $date(event.registrationFrom) }}
                                </time>
                            </p>

                            <template v-if="event.registrationStatus == 'open' && selection.length > 0">
                                <h4 class="event-card-show__title mt-4">
                                    {{ $t("@championship:championship.organization") }}
                                </h4>
                                <template v-for="org in selection">
                                    <div v-if="org.organizationLevel === 'main'">
                                        <div class="row">
                                            <div class="col-md-4">
                                                {{ org.name }}
                                            </div>
                                            <div class="col-md-2">
                                                <button class="btn btn-primary" @click="select(org)">
                                                    {{ $t("@championship:registration.window.organization.button") }}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row my-2" v-for="child in org.children">
                                            <div class="col-md-4 pl-5">
                                                {{ child.name }}
                                            </div>
                                            <div class="col-md-2">
                                                <button class="btn btn-primary" @click="select(child)">
                                                    {{ $t("@championship:registration.window.organization.button") }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <RouterLink :to="event.landingPage.path" class="btn btn-primary mt-4" v-if="event.landingPage">
                            {{ $t("@championship:championship.landingPage.link") }}
                        </RouterLink>
                    </div>
                </div>
                <div class="row"></div>
            </div>
        </div>
    </div>
</template>
